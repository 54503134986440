import React from 'react'
import './HorizontalSimpleCard.scss'
import classNames from 'classnames'
import { CardProps, RailDetailsItem } from '@interfaces/index'
import { ImageSize } from '@enums/images.enum'
import { useImgUrlTransform } from '@hooks/useImgUrlTransform'
import { DocumentData } from 'firebase/firestore'
import { useTypedSelector } from 'store'
import Image from '@components/Image/Image'

interface HorizontalSimpleCardProps {
  item: CardProps | RailDetailsItem | DocumentData
  isActive?: boolean
}

const HorizontalSimpleCard = ({ item, isActive }: HorizontalSimpleCardProps) => {
  const config = useTypedSelector((state) => state.config.data)
  const fallbackImage = config?.features.fallbackImages.horizontalCard ?? ''
  const {
    id,
    title,
    contextData: { imageBackdrop, imageCard },
  } = item
  const transformedImage = useImgUrlTransform(fallbackImage, imageCard ?? imageBackdrop, ImageSize.HORIZONTAL_CARD)

  return (
    <div
      className={classNames('hs-type', {
        'hs-type__full_opacity': isActive,
      })}
      id={`more-like-this-${id}`}
    >
      <div
        className={classNames('hs-type__focus', {
          'hs-type__focused': isActive,
        })}
      />
      <div className={classNames('card-image-wrapper', { 'row-card-image-focused': isActive })}>
        <Image image={transformedImage} alt={title} />
      </div>
    </div>
  )
}
export default HorizontalSimpleCard
