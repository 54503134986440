import React from 'react'
import './EpisodeCard.scss'
import { CardProps, RailDetailsItem } from '@interfaces/index'
import { ImageSize } from '@enums/images.enum'
import { useImgUrlTransform } from '@hooks/useImgUrlTransform'
import { DocumentData } from 'firebase/firestore'
import { useTypedSelector } from 'store'
import Image from '@components/Image/Image'
import Text from '@components/Text/Text'
import { TextTag } from '@enums/textTag'
import classNames from 'classnames'

interface EpisodeCardProps {
  item: CardProps | RailDetailsItem | DocumentData
  isActive?: boolean
  index?: number
  info?: string
  onFocusUpId?: string | null
}

const EpisodeCard = ({ item, isActive, index, info, onFocusUpId }: EpisodeCardProps) => {
  const config = useTypedSelector((state) => state.config.data)
  const fallbackImage = config?.features.fallbackImages.horizontalCard ?? ''
  const {
    id,
    title,
    contextData: { Thumbnail },
    description,
  } = item
  const transformedImage = useImgUrlTransform(fallbackImage, Thumbnail, ImageSize.HORIZONTAL_CARD)

  return (
    <div className={classNames('ec-type', { active: isActive })} id={id} data-sn-up={onFocusUpId}>
      <div className="ec-type-top">
        <Image image={transformedImage} alt={title} />
        <Text tag={TextTag.P} className="episode-season">
          {info}
        </Text>
        <div className="episode-video-bar">
          <div className="episode-video-bar-progress"></div>
        </div>
      </div>
      <div className="ec-type-bottom">
        <Text tag={TextTag.P} className="episode-name">
          {index !== undefined ? `${index + 1}. ${title}` : `${title}`}
        </Text>
        <Text tag={TextTag.P} className="episode-description">
          {description}
        </Text>
        {/* Currently we don't receive data from BE for the episodes length, this will remain commented until next changes */}
        {/* <Text tag={TextTag.P} className="episode-length"></Text> */}
      </div>
    </div>
  )
}

export default EpisodeCard
