import React from 'react'
import './HorizontalComplicatedDateCard.scss'
import classNames from 'classnames'
import { CardProps, Genre, RailDetailsItem } from '@interfaces/index'
import { convertDate, formatSecondsToHoursAndMinutes } from '@utils/helpers'
import { DocumentData } from 'firebase/firestore'
import { useTypedSelector } from 'store'
import { ImageSize } from '@enums/images.enum'
import { TextTag } from '@enums/textTag'
import Image from '@components/Image/Image'
import Text from '@components/Text/Text'
import { useImgUrlTransform } from '@hooks/useImgUrlTransform'

interface HorizontalComplicatedDateCardProps {
  item: CardProps | RailDetailsItem | DocumentData
  isActive?: boolean
}

const HorizontalComplicatedDateCard = ({ item, isActive }: HorizontalComplicatedDateCardProps) => {
  const config = useTypedSelector((state) => state.config.data)
  const fallbackImage = config?.features.fallbackImages.horizontalCard ?? ''
  const {
    title,
    contextData: { imageBackdrop, genres, duration, releaseDate, imageCard },
  } = item
  const transformedImage = useImgUrlTransform(fallbackImage, imageCard ?? imageBackdrop, ImageSize.HORIZONTAL_CARD)

  return (
    <div
      className={classNames('hcd-type', {
        'hcd-type__is_active': isActive,
        'hcd-type__full_opacity': isActive,
      })}
    >
      <div className="img-placeholder"></div>
      <div className="hcd-type__top-container">
        <div
          className={classNames('hcd-type__focus', {
            'hcd-type__focused': isActive,
          })}
        ></div>
        <div className={classNames('card-image-wrapper', { 'row-card-image-focused': isActive })}>
          <Image image={transformedImage} alt={title} />
        </div>
        <div className={classNames(`tags-container`, { 'tags-container__is_active': isActive })}>
          {genres?.map((genre: Genre, index: number) =>
            index <= 1 ? (
              <Text tag={TextTag.P} key={genre.name} className="single-tag">
                {genre.name}
              </Text>
            ) : null,
          )}
        </div>
        {duration && (
          <div className={classNames('duration-container', { 'duration-container__is_active': isActive })}>
            {formatSecondsToHoursAndMinutes(duration)}
          </div>
        )}
      </div>
      <div className={'hcd-type__bottom-container hcd-type__bottom-container-one-line'}>
        <Text tag={TextTag.P}>{title}</Text>
        <Text tag={TextTag.P}>{releaseDate && convertDate(releaseDate)}</Text>
      </div>
    </div>
  )
}
export default HorizontalComplicatedDateCard
