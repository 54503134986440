import { useCallback, useEffect, useState } from 'react'
import { ClosedSideMenu } from '@components/SideMenu/ClosedSideMenu/ClosedSideMenu'
import { OpenSideMenu } from '@components/SideMenu/OpenSideMenu/OpenSideMenu'
import './SideMenu.scss'
import SpatialNavigation from '@utils/spatial-navigation.js'
import { KeyCode, KeyName } from '@constants/index'
import { useDispatch } from 'react-redux'
import { fetchMenu, setOpenMenu } from '@actions/menuActions'
import { useTypedSelector } from 'store'
import { getLanguage } from '@utils/vocab'
import { MenuItem } from '@interfaces/index'
import classNames from 'classnames'

type SideMenuProps = {
  showMenu: boolean
}

export const SideMenu = ({ showMenu }: SideMenuProps) => {
  const SN = SpatialNavigation
  const [closedMenuAnimation, setClosedMenuAnimation] = useState(false)
  const dispatch = useDispatch()
  const isMenuOpen = useTypedSelector((state) => state.menu.openMenu)
  const menuContent = useTypedSelector((state) => state.menu.data?.mainMenu?.content)
  const [menuItems, setMenuItems] = useState<MenuItem[]>()
  const showSkeleton = useTypedSelector((state) => state.skeleton.show)
  const config = useTypedSelector((state) => state.config.data)

  useEffect(() => {
    const language = getLanguage()

    config && dispatch(fetchMenu(language))
  }, [config, dispatch])

  useEffect(() => {
    const menuItems: MenuItem[] = []
    menuContent.forEach((menuItem) => {
      const item = {
        text: menuItem.text,
        path: menuItem.callToActions.webUrl,
        icon: menuItem.icon,
      }

      menuItems.push(item)
    })

    setMenuItems(menuItems)
  }, [menuContent])

  const closeMenu = useCallback(
    (e: KeyboardEvent) => {
      const backKeyPressed =
        e.key === KeyName.Escape || e.keyCode === KeyCode.TizenBackKey || e.keyCode === KeyCode.WebosBackKey

      if (isMenuOpen) {
        if (e.key === KeyName.ArrowRight || backKeyPressed) {
          setTimeout(() => {
            dispatch(setOpenMenu(false))
            setClosedMenuAnimation(true)
          }, 0)
          if (backKeyPressed) {
            SN.move('right')
          }
        }
      }
    },
    [isMenuOpen, dispatch, SN],
  )

  const handleSideMenuOnClick = useCallback(() => {
    dispatch(setOpenMenu(true))
  }, [dispatch])

  const handleOpenMenu = (value: boolean) => {
    dispatch(setOpenMenu(value))
  }

  useEffect(() => {
    document.addEventListener('keydown', closeMenu, false)

    return () => {
      document.removeEventListener('keydown', closeMenu, false)
      dispatch(setOpenMenu(false))
      setClosedMenuAnimation(true)
    }
  }, [closeMenu, dispatch])

  return (
    <div
      id="menu"
      className={classNames('menu', { focusable: !isMenuOpen && !showSkeleton }, { visible: showMenu })}
      tabIndex={-1}
      onFocus={handleSideMenuOnClick}
    >
      {isMenuOpen ? (
        <OpenSideMenu setOpenMenu={handleOpenMenu} menuItems={menuItems} />
      ) : (
        <ClosedSideMenu closedMenuAnimation={closedMenuAnimation} menuItems={menuItems} />
      )}
    </div>
  )
}
