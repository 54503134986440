import React, { useCallback, useRef, useState } from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/react-splide/css'
import './HeroMoviesFullWidth.scss'
import { CardTypes, carouselHeroRailInterval } from '@constants/index'
import { CardSessionStorage, Movie, Section } from '@interfaces/index'
import { useLocation, useNavigate } from 'react-router-dom'
import { addRailItemToSS, onEnterKeyPress } from '@utils/helpers'
import Card from '@components/Cards/Card'
import useCarousel from '@hooks/useCarousel'
import { focusableRoutes } from '@constants/routes.const'

export function HeroMoviesFullWidth({ section }: { section: Section }) {
  const nextState = useRef<HTMLButtonElement>(null)
  const prevState = useRef<HTMLButtonElement>(null)
  const carouselRef = useRef<HTMLDivElement>(null)
  const [isFocused, setIsFocused] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const path = location.pathname
  const lastFocusedCardInSS: CardSessionStorage = JSON.parse(sessionStorage.getItem('lastFocusedCard-home') as string)
  const [currentIndex, setCurrentIndex] = useState<number>(
    lastFocusedCardInSS && lastFocusedCardInSS.railId === `rail-${section.id}` ? lastFocusedCardInSS.cardIndex : 0,
  )
  const railSpeed = 800

  const moveLeft = useCallback(() => {
    currentIndex > 0 && prevState.current?.click()
  }, [currentIndex])

  const moveRight = useCallback(() => {
    nextState.current?.click()
  }, [])

  useCarousel(carouselRef.current, moveLeft, moveRight, currentIndex)

  const navigateToDetailsPage = useCallback(
    (e: React.KeyboardEvent) => {
      onEnterKeyPress(e, () => {
        addRailItemToSS(`rail-${section.id}`, `${section.items[currentIndex].id}`, currentIndex, path)
        navigate(section.items[currentIndex].callToActions.webUrl)
      })
    },
    [currentIndex, navigate, path, section.id, section.items],
  )

  const handleIsFocusedOnFocus = () => {
    setIsFocused(true)
  }

  const handleIsFocusedOnBlur = () => {
    setIsFocused(false)
  }

  return (
    <div
      className="focusable splide-custom-movies scroll-center"
      data-sn-left={currentIndex === 0 && `#${focusableRoutes.MENU}`}
      data-sn-right=""
      tabIndex={-1}
      ref={carouselRef}
      onFocus={handleIsFocusedOnFocus}
      onBlur={handleIsFocusedOnBlur}
      onKeyDown={navigateToDetailsPage}
      id={`rail-${section.id}`}
    >
      <Splide
        onMove={(_splide, index) => setCurrentIndex(index)}
        options={{
          width: '100%',
          height: '100%',
          type: 'loop',
          autoplay: true,
          interval: carouselHeroRailInterval,
          speed: railSpeed,
          pauseOnFocus: true,
          slideFocus: true,
          start: currentIndex,
          focus: 0,
        }}
      >
        {section.items.map((item: Movie, index) => (
          <SplideSlide key={`${item.id}_${index}`}>
            <Card
              item={item}
              rowType={CardTypes.HORIZONTAL_SIMPLE_POSTER_CAROUSEL}
              isActive={isFocused && currentIndex === index}
              isFocusable={false}
            />
          </SplideSlide>
        ))}
        <div className="splide__arrows">
          <button className="splide__arrow splide__arrow--prev" ref={prevState}></button>
          <button className="splide__arrow splide__arrow--next" ref={nextState}></button>
        </div>
      </Splide>
    </div>
  )
}
