import {
  FetchVocabSuccess,
  FetchVocabFailure,
  FetchVocabInit,
  FetchVocabRequest,
} from '../actions-types/vocabularyActionTypes'
import { FETCH_VOCAB_FAILURE, FETCH_VOCAB_SUCCESS } from '../actions/vocabularyActions'
import { Vocab } from '../interfaces'

interface State {
  data: Vocab
  error: string
}

const vocabDataObject = {
  init: '',
}

const initialState: State = {
  data: vocabDataObject,
  error: '',
}

const actionInit = {
  type: '',
  payload: {},
}

export type Action = FetchVocabSuccess | FetchVocabFailure | FetchVocabInit | FetchVocabRequest

export const vocabularyReducer = (state: State = initialState, action: Action = actionInit): State => {
  switch (action.type) {
    case FETCH_VOCAB_SUCCESS:
      return {
        ...state,
        data: (action as FetchVocabSuccess).payload,
        error: '',
      }
    case FETCH_VOCAB_FAILURE:
      return { ...state, error: (action as FetchVocabFailure).payload }
    default:
      return state
  }
}
