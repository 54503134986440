import React from 'react'
import './VerticalSimpleCard.scss'
import classNames from 'classnames'
import { CardProps, RailDetailsItem } from '@interfaces/index'
import { ImageSize } from '@enums/images.enum'
import { useImgUrlTransform } from '@hooks/useImgUrlTransform'
import { DocumentData } from 'firebase/firestore'
import { useTypedSelector } from 'store'
import Image from '@components/Image/Image'

interface VerticalSimpleCardProps {
  item: CardProps | RailDetailsItem | DocumentData
  isActive?: boolean
}

const VerticalSimpleCard = ({ item, isActive }: VerticalSimpleCardProps) => {
  const config = useTypedSelector((state) => state.config.data)
  const fallbackImage = config?.features.fallbackImages.verticalCard ?? ''
  const {
    title,
    contextData: { imagePoster },
  } = item
  const transformedImage = useImgUrlTransform(fallbackImage, imagePoster, ImageSize.VERTICAL_CARD)

  return (
    <div
      className={classNames('vs-type', {
        'vs-type__full_opacity': isActive,
      })}
    >
      <div
        className={classNames('vs-type__focus', {
          'vs-type__focused': isActive,
        })}
      ></div>
      <div
        className={classNames(
          'card-image-wrapper vertical',
          { 'row-card-image-focused': isActive },
          { 'fallback-image': !imagePoster },
        )}
      >
        <Image image={transformedImage} alt={title} />
      </div>
    </div>
  )
}

export default VerticalSimpleCard
