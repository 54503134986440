import React, { useCallback, useEffect, useRef, useState } from 'react'
import './MovieHero.scss'
import { Genre, Season, CallToActions } from '@interfaces/index'
import ActionButton from '@components/Buttons/ActionButton/ActionButton'
import CTAButton from '@components/Buttons/CTAButton/CTAButton'
import { addItemToMyList, getMyListItemById, removeItemFromMyList } from '@services/myList'
import { addRailItemToSS, ageRateColorClass } from '@utils/helpers'
import { useLocation, useNavigate } from 'react-router-dom'
import Image from '@components/Image/Image'
// import { routes } from '@constants/routes.const'
import { useTypedSelector } from 'store'
import { language } from '@constants/lang.const'
import { ButtonIcon } from '@constants/index'
import Text from '@components/Text/Text'
import { TextTag } from '@enums/textTag'
import classNames from 'classnames'
import { ImageExtension, ImageSize } from '@enums/images.enum'
import { useImgUrlTransform } from '@hooks/useImgUrlTransform'

interface HeroProps {
  id: string
  title: string
  subtitle: string
  percentageMatchScore: number
  duration: number
  releaseDate: string
  quality: string
  ageRating: string
  genres: Genre[]
  description: string
  links: []
  image: string
  imageCard: string
  imageTitle: string
  contentCategory: string
  callToActions: CallToActions
  seasons?: Season[]
  index: number
  railId: number
}

export const Hero = ({
  ageRating,
  genres,
  description,
  image,
  imageCard,
  duration,
  imageTitle,
  id,
  title,
  subtitle,
  callToActions,
  releaseDate,
  // seasons,
  index,
  railId,
}: HeroProps) => {
  const [isMovieInMyList, setIsMovieInMyList] = useState(false)
  const userObject = JSON.parse(localStorage.getItem('userInfo') as string)
  const userIDFromLS = userObject?.uid
  const navigate = useNavigate()
  const V = useTypedSelector((state) => state.vocabulary.data)
  const matchValue = V.heroMatchScore
  const getVocabFromLS = JSON.parse(localStorage.getItem(userObject?.uid) as string)
  const { EN, ES } = language
  const actionBtnClassName = getVocabFromLS === ES ? ES : EN
  const ctaButtonRef = useRef<HTMLButtonElement>(null)
  const location = useLocation()
  const path = location.pathname
  const config = useTypedSelector((state) => state.config.data)
  const fallbackImage = config?.features.fallbackImages.horizontalCard ?? ''
  const transformedImageTitle = useImgUrlTransform('', imageTitle, ImageSize.TITLE, ImageExtension.PNG)
  const transformedImageHero = useImgUrlTransform(fallbackImage, image, ImageSize.HERO)

  // const navigateToPlayer = useCallback(() => {
  //   if (seasons !== undefined) {
  //     const episodeVideoUrl = seasons?.[0].episodes?.[0].slug
  //     navigate(`${routes.PLAYER}/${episodeVideoUrl}`)
  //   } else {
  //     navigate(`${routes.PLAYER}/${id}`)
  //   }
  // }, [seasons, navigate, id])

  const addToMyListButtonClick = () => {
    addItemToMyList(userIDFromLS, {
      id,
      title,
      subtitle: subtitle || '',
      image: imageCard ?? image,
      callToActions,
      duration: duration || 0,
      genres: genres || [],
      releaseDate: releaseDate || '',
    }).then(() => setIsMovieInMyList(true))
  }

  const removeFromMyListButtonClick = () => {
    removeItemFromMyList(userIDFromLS, id).then(() => {
      setIsMovieInMyList(false)
    })
  }

  const navigateToDetailsPage = useCallback(() => {
    addRailItemToSS(`rail-${railId}`, id, index, path, `cta-btn-${id}`)
    navigate(callToActions.webUrl)
  }, [callToActions.webUrl, id, index, navigate, path, railId])

  const addRemoveFromListOnClick = () => {
    isMovieInMyList ? removeFromMyListButtonClick() : addToMyListButtonClick()
  }

  useEffect(() => {
    getMyListItemById(userIDFromLS, id).then((res) => {
      if (res) setIsMovieInMyList(true)
    })
  }, [id, userIDFromLS])

  return (
    <div className="hero">
      <div className="hero-background-image">
        <Image type="hero" className="hero-image" image={transformedImageHero} alt={`img-${title}`} />
      </div>
      <div className="hero-info">
        <Image type="title" image={transformedImageTitle} alt={title} className="hero-image-title" />
        <div className="hero-details">
          <Text tag={TextTag.SPAN} className="match">
            {matchValue}
          </Text>
          <Text tag={TextTag.SPAN} className={ageRateColorClass(ageRating)}>
            {ageRating}
          </Text>
          {/* Always render max 2 genres */}
          {genres &&
            genres.slice(0, 2).map((genre, index) => (
              <Text tag={TextTag.SPAN} className="hero-genre" key={genre.id}>
                {genre.name}
                {genres.length >= 0 && index === 0 ? ', ' : ''}
              </Text>
            ))}
          {subtitle && (
            <Text tag={TextTag.SPAN} className="subtitle">
              {subtitle}
            </Text>
          )}
        </div>
        <Text tag={TextTag.P} className="hero-description">
          {description}
        </Text>
        <div className="hero-buttons">
          <CTAButton
            icon={ButtonIcon.PLAY}
            contentCategory={''}
            isTop={index === 0}
            buttonText={V.heroPlayButtonLabel}
            onClick={navigateToDetailsPage}
            ctaButtonRef={ctaButtonRef}
            className={index === 0 ? 'scroll-top' : 'scroll-center'}
            id={id}
          />
          <div className={classNames('action-buttons', actionBtnClassName)} tabIndex={-1}>
            <ActionButton
              icon={isMovieInMyList ? ButtonIcon.TICK : ButtonIcon.PLUS}
              isTop={index === 0}
              fromButton
              onClick={addRemoveFromListOnClick}
              className={index === 0 ? 'scroll-top' : 'scroll-center'}
              id={`myList-btn-${index}`}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
