import React from 'react'
import './HorizontalSimplePosterCarouselCard.scss'
import classNames from 'classnames'
import { CardProps, RailDetailsItem } from '@interfaces/index'
import { DocumentData } from 'firebase/firestore'
import { ImageSize } from '@enums/images.enum'
import { useImgUrlTransform } from '@hooks/useImgUrlTransform'
import { useTypedSelector } from 'store'
import Image from '@components/Image/Image'

interface HorizontalSimplePosterCarouselCardProps {
  item: CardProps | RailDetailsItem | DocumentData
  isActive?: boolean
}

const HorizontalSimplePosterCarouselCard = ({ item, isActive }: HorizontalSimplePosterCarouselCardProps) => {
  const config = useTypedSelector((state) => state.config.data)
  const fallbackImage = config?.features.fallbackImages.horizontalCard ?? ''
  const {
    id,
    title,
    contextData: { imageCard, imageBackdrop },
  } = item
  const transformedImage = useImgUrlTransform(fallbackImage, imageCard ?? imageBackdrop, ImageSize.CAROUSEL)

  return (
    <div
      className={classNames('hspc-type', {
        'hspc-type__focus': isActive,
      })}
      id={id}
    >
      <div className={classNames('card-image-wrapper', { 'row-card-image-focused': isActive })}>
        <Image type="poster" image={transformedImage} alt={title} />
      </div>
    </div>
  )
}
export default HorizontalSimplePosterCarouselCard
