import React, { useCallback } from 'react'
import './Card.scss'
import classNames from 'classnames'
import { CardProps, HorizontalCardProps, RailDetailsItem } from '@interfaces/index'
import { onEnterKeyPressAndClick } from '@utils/helpers'
import { useLocation, useNavigate } from 'react-router-dom'
import { CardTypes } from '@constants/index'
import { DocumentData } from 'firebase/firestore'
import { routes } from '@constants/routes.const'
import HorizontalCategoryCard from './HorizontalCategoryCard/HorizontalCategoryCard'
import HorizontalComplicatedCard from './HorizontalComplicatedCard/HorizontalComplicatedCard'
import HorizontalComplicatedDateCard from './HorizontalComplicatedDateCard/HorizontalComplicatedDateCard'
import SimplePosterCarouselCard from './HorizontalSimplePosterCarouselCard/HorizontalSimplePosterCarouselCard'
import VerticalSimpleCard from './VerticalSimpleCard/VerticalSimpleCard'
import HorizontalSimpleCard from './HorizontalSimpleCard/HorizontalSimpleCard'
import HorizontalContinueWatchingCard from './HorizontalContinueWatchingCard/HorizontalContinueWatchingCard'
import EpisodeCard from './EpisodeCard/EpisodeCard'
import HorizontalCardWithTitle from './HorizontalCardWithTitle/HorizontalCardWithTitle'

const Card = ({
  item,
  rowType,
  isActive,
  isFocusable,
  info,
  onFocusUpId,
  index,
  disableMoveDown,
  disableMoveUp,
  lastFocusedCard,
  onFocusLeft,
}: HorizontalCardProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const moveDown = isFocusable && disableMoveDown
  const moveUp = isFocusable && disableMoveUp
  const moveLeft = isFocusable && onFocusLeft

  const renderCard = (
    item: CardProps | RailDetailsItem | DocumentData,
    rowType?: string,
    isActive?: boolean,
    info?: string,
    onFocusUpId?: string | null,
    index?: number,
  ) => {
    switch (rowType) {
      case CardTypes.VERTICAL_SIMPLE:
        return <VerticalSimpleCard item={item} isActive={isActive} />
      case CardTypes.HORIZONTAL_CATEGORY:
        return <HorizontalCategoryCard item={item} isActive={isActive} />
      case CardTypes.HORIZONTAL_SIMPLE:
        return <HorizontalSimpleCard item={item} isActive={isActive} />
      case CardTypes.HORIZONTAL_CONTINUE_WATCHING:
        return <HorizontalContinueWatchingCard item={item} isActive={isActive} />
      case CardTypes.HORIZONTAL_COMPLICATED:
        return <HorizontalComplicatedCard item={item} isActive={isActive} />
      case CardTypes.HORIZONTAL_COMPLICATED_DATE:
        return <HorizontalComplicatedDateCard item={item} isActive={isActive} />
      case CardTypes.HORIZONTAL_SIMPLE_POSTER_CAROUSEL:
        return <SimplePosterCarouselCard item={item} isActive={isActive} />
      case CardTypes.SEARCH:
      case CardTypes.MY_LIST:
        return <HorizontalCardWithTitle item={item} />
      case CardTypes.EPISODES:
        return <EpisodeCard item={item} isActive={isActive} index={index} info={info} onFocusUpId={onFocusUpId} />
      default:
        return <HorizontalSimpleCard item={item} isActive={isActive} />
    }
  }

  const handleNavigation = useCallback(
    (e: React.KeyboardEvent | React.MouseEvent) => {
      e.preventDefault()
      onEnterKeyPressAndClick(e, () => navigate(item.callToActions.webUrl))
    },
    [item?.callToActions?.webUrl, navigate],
  )

  const cardId = useCallback(
    (id: string) => (location.pathname.includes(routes.SEARCH) ? `search-${id}` : id),
    [location.pathname],
  )

  return (
    <div
      className={classNames('card-container scroll-center', isFocusable && 'focusable')}
      id={cardId(item.id)}
      tabIndex={isFocusable ? -1 : 0}
      onKeyDown={handleNavigation}
      onClick={handleNavigation}
      data-sn-down={moveDown}
      data-sn-up={onFocusUpId ?? moveUp}
      data-sn-left={moveLeft}
      onFocus={lastFocusedCard}
    >
      {renderCard(item, rowType, isActive, info, onFocusUpId, index)}
    </div>
  )
}

export default Card
