import axios from 'axios'
import { Dispatch } from 'redux'
import { Vocab } from '../interfaces'
import { Action } from '../reducers/vocabularyReducer'
import { Language } from '@constants/index'

export const FETCH_VOCAB_REQUEST = 'FETCH_VOCAB_REQUEST' as const
export const FETCH_VOCAB_SUCCESS = 'FETCH_VOCAB_SUCCESS' as const
export const FETCH_VOCAB_FAILURE = 'FETCH_VOCAB_FAILURE' as const

export const fetchVocabRequest = () => ({ type: FETCH_VOCAB_REQUEST })

export const fetchVocabSuccess = (vocabulary: Vocab) => ({
  type: FETCH_VOCAB_SUCCESS,
  payload: vocabulary,
})

export const fetchVocabFailure = (error: string) => ({
  type: FETCH_VOCAB_FAILURE,
  payload: error,
})

export const fetchVocabulary = (language: string) => {
  return (dispatch: Dispatch<Action>) => {
    try {
      dispatch(fetchVocabRequest())
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/parsed/${language}/vocabulary`)
        .then((res) => {
          const vocabulary: Vocab = res.data
          dispatch(fetchVocabSuccess(vocabulary))
        })
        .catch((error) => {
          const errorMsg = error.message
          dispatch(fetchVocabFailure(errorMsg))
          axios.get(`${process.env.REACT_APP_BASE_URL}/parsed/${Language.EN}/vocabulary`).then((res) => {
            const vocabulary: Vocab = res.data
            dispatch(fetchVocabSuccess(vocabulary))
            const userObject = JSON.parse(localStorage.getItem('userInfo') as string)
            localStorage.setItem(`${userObject?.uid}`, JSON.stringify(Language.EN))
          })
        })
    } catch (error) {
      console.log('Error fetching the desired language:', error, 'The app will be switched to English')
    }
  }
}
