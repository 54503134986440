import { ImageExtension } from '@enums/images.enum'
import { useTypedSelector } from 'store'

export const useImgUrlTransform = (
  fallbackImage: string,
  image: string,
  format: string,
  extension: ImageExtension = ImageExtension.WEBP,
) => {
  const config = useTypedSelector((state) => state.config.data)
  const overrideFormat = config?.dynamicImages.overrides.format
  const overrideExtension = config?.dynamicImages.overrides.extension
  const enabledImgTransform = config?.dynamicImages.enabled

  if (image) {
    if (enabledImgTransform && overrideFormat && overrideExtension) {
      return image.replace(overrideFormat, format).replace(overrideExtension, extension)
    } else {
      return image
    }
  } else {
    return fallbackImage
  }
}
