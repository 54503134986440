// @ts-nocheck

import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Params, useParams } from 'react-router-dom'
import { Player, PlayerConfig, SourceConfig } from 'bitmovin-player/modules/bitmovinplayer-core'
import PolyfillModule from 'bitmovin-player/modules/bitmovinplayer-polyfill'
import EngineBitmovinModule from 'bitmovin-player/modules/bitmovinplayer-engine-bitmovin'
import EngineNativeModule from 'bitmovin-player/modules/bitmovinplayer-engine-native'
import ContainerMp4Module from 'bitmovin-player/modules/bitmovinplayer-container-mp4'
import MseRendererModule from 'bitmovin-player/modules/bitmovinplayer-mserenderer'
import AbrModule from 'bitmovin-player/modules/bitmovinplayer-abr'
import DrmModule from 'bitmovin-player/modules/bitmovinplayer-drm'
import XmlModule from 'bitmovin-player/modules/bitmovinplayer-xml'
import DashModule from 'bitmovin-player/modules/bitmovinplayer-dash'
import CryptoModule from 'bitmovin-player/modules/bitmovinplayer-crypto'
import TizenModule from 'bitmovin-player/modules/bitmovinplayer-tizen'
import WebosModule from 'bitmovin-player/modules/bitmovinplayer-webos'
import StyleModule from 'bitmovin-player/modules/bitmovinplayer-style'
import HlsModule from 'bitmovin-player/modules/bitmovinplayer-hls'
import ContainerTSModule from 'bitmovin-player/modules/bitmovinplayer-container-ts'
import SubtitlesModule from 'bitmovin-player/modules/bitmovinplayer-subtitles'
import SubtitlesCEA608Module from 'bitmovin-player/modules/bitmovinplayer-subtitles-cea608'
import BitmovinSubtitlesNativeModule from 'bitmovin-player/modules/bitmovinplayer-subtitles-native'
import SubtitlesTTMLModule from 'bitmovin-player/modules/bitmovinplayer-subtitles-ttml'
import SubtitlesVTTModule from 'bitmovin-player/modules/bitmovinplayer-subtitles-vtt'
import AdvertisingCoreModule, { AdvertisingConfig } from 'bitmovin-player/modules/bitmovinplayer-advertising-core'
import AdvertisingImaModule from 'bitmovin-player/modules/bitmovinplayer-advertising-ima'
import AdvertisingOmsdkModule from 'bitmovin-player/modules/bitmovinplayer-advertising-omsdk'
import AnalyticsModule from 'bitmovin-player/modules/bitmovinplayer-analytics'
import './BitmovinPlayer.scss'
import 'bitmovin-player-ui/dist/css/bitmovinplayer-ui.min.css'
import { StreamUrlParsed, VideoType } from '@interfaces/player.interface'
import { getVideoInfo, reduceAdsConfig, reduceUrlByType } from '@utils/player.util'
import { NPAW_analytics, defaultPlayerConfig, defaultSourceConfig } from '@constants/player.const'
import { DRMConfig } from 'bitmovin-player'
import DotSpinner from '@components/DotSpinner/DotSpinner'
import { PlayerControls } from '@components/PlayerControls/PlayerControls'
import { useTypedSelector } from 'store'
import { Subtitle } from '@interfaces/index'
import { checkUser } from '@utils/auth'
import { useDispatch } from 'react-redux'
import { setAudio, setAudioList, setSubtitle, setSubtitleList, setIsPlaying } from '@actions/playerActions'
import classNames from 'classnames'
import { StreamUrlType } from '@enums/player.enum'
import { useImgUrlTransform } from '@hooks/useImgUrlTransform'
import { ImageSize } from '@enums/images.enum'

interface BitmovinPlayerProps {
  showMenu: (status: boolean) => void
  defaultPlayerConfig?: PlayerConfig
  defaultSourceConfig?: SourceConfig
}

export const BitmovinPlayer = ({ showMenu }: BitmovinPlayerProps) => {
  const apiToken = localStorage.getItem('userToken') as string
  const [videoConfig, setVideoConfig] = useState<VideoType | undefined>(undefined)
  const [player, setPlayer] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [shouldGetInfo, setShouldGetInfo] = useState(false)
  const [hasPlaybackEnded, setHasPlaybackEnded] = useState(false)
  const [currentTime, setCurrentTime] = useState(0)
  const playerDiv = useRef()
  const progressRef = useRef<HTMLProgressElement>(null)
  const { slug } = useParams<Params>()
  const dispatch = useDispatch()
  const [showModal, setShowModal] = useState(false)
  const savedSubtitle = useTypedSelector((state) => state.player.subtitle)
  const savedAudio = useTypedSelector((state) => state.player.audio)
  const subtitlesList = useTypedSelector((state) => state.player.subtitleList)
  const audioList = useTypedSelector((state) => state.player.audioList)
  const findAudioFromStore = audioList.find((audio) => audio.id === savedAudio)
  const [adIsPlaying, setAdIsPlaying] = useState(false)
  const duration = player && player.getDuration()
  const [loadDefaultVideo, setLoadDefaultVideo] = useState(false)
  const posterImage = useImgUrlTransform('', videoConfig?.contextData?.Thumbnail, ImageSize.VIDEO_THUMBNAIL)

  const play = useCallback(() => {
    if (player) {
      player.play()
      dispatch(setIsPlaying(true))
    }
  }, [dispatch, player])

  const pause = useCallback(() => {
    if (player) {
      player.pause()
      dispatch(setIsPlaying(false))
    }
  }, [dispatch, player])

  const playFromBeginning = useCallback(() => {
    if (player) {
      player.seek(0)
      play()
      hasPlaybackEnded && setHasPlaybackEnded(false)
    }
  }, [player, hasPlaybackEnded, play])

  const playFromPosition = useCallback(
    (position: number) => {
      if (player) {
        const seeked = player.seek(position)
        if (seeked) {
          setCurrentTime(position)
          play()
          hasPlaybackEnded && setHasPlaybackEnded(false)
        }
      }
    },
    [player, setCurrentTime, play, hasPlaybackEnded],
  )

  useEffect(() => {
    if (player) {
      player.on('playbackfinished', () => {
        setAdIsPlaying(false)
        setHasPlaybackEnded(true)
        setCurrentTime(player.getDuration())
      })

      player.on('adstarted', () => {
        setAdIsPlaying(true)
        play()
      })

      player.on('timechanged', () => {
        if (!player.ads.getActiveAd()) {
          setCurrentTime(player.getCurrentTime())
          setAdIsPlaying(false)
        }
      })
    }
  }, [adIsPlaying, player, play])

  useEffect(() => {
    setIsLoading(true)
    const handleError = (error) => {
      console.log('Error getting Video Config:', error)
      setVideoConfig(undefined)
      setIsLoading(false)
    }
    if (apiToken) {
      void getVideoInfo(slug, apiToken)
        .then((config) => {
          if (!config.error) {
            setVideoConfig(config)
            setIsLoading(false)
          } else if (config.error.status === 401 && !shouldGetInfo) {
            checkUser(() => setShouldGetInfo(true), handleError)
          } else {
            handleError(`Error ${config.error.status}: ${config.error.message}`)
            setLoadDefaultVideo(true)
          }
        })
        .catch(handleError)
    }
  }, [apiToken, slug, shouldGetInfo])

  useEffect(() => {
    if (player) {
      const youbora = require('youboralib')
      require('youbora-adapter-bitmovin8')

      if (typeof youbora !== 'undefined' && youbora.adapters.Bitmovin8) {
        const plugin = new youbora.Plugin(NPAW_analytics)

        plugin.setAdapter(new youbora.adapters.Bitmovin8(player))
        plugin.setOptions({
          enabled: true,
          username: process.env.REACT_APP_NPAW_ANALYTICS_USERNAME,
          'content.title': videoConfig?.title,
          'content.id': videoConfig?.slug,
          'content.transactionCode': 'transactionTest',
          'content.genre': videoConfig?.contextData?.genres?.map((genre) => `${genre.name}`),
          'device.name': 'Smart TV Velvet',
        })
      }
    }
  }, [player, videoConfig])

  useEffect(() => {
    showMenu(false)

    const setupPlayer = () => {
      Player.addModule(EngineBitmovinModule)
      Player.addModule(EngineNativeModule)
      Player.addModule(MseRendererModule)
      Player.addModule(XmlModule)
      Player.addModule(DashModule)
      Player.addModule(DrmModule)
      Player.addModule(AbrModule)
      Player.addModule(ContainerMp4Module)
      Player.addModule(PolyfillModule)
      Player.addModule(StyleModule)
      Player.addModule(CryptoModule)
      Player.addModule(TizenModule)
      Player.addModule(WebosModule)
      Player.addModule(SubtitlesModule)
      Player.addModule(SubtitlesCEA608Module)
      Player.addModule(ContainerTSModule)
      Player.addModule(HlsModule)
      Player.addModule(BitmovinSubtitlesNativeModule)
      Player.addModule(SubtitlesVTTModule)
      Player.addModule(SubtitlesTTMLModule)
      Player.addModule(AdvertisingCoreModule)
      Player.addModule(AdvertisingImaModule)
      Player.addModule(AdvertisingOmsdkModule)
      Player.addModule(AnalyticsModule)

      const { title, streamUrls, drmConfigs, adsConfig } = videoConfig || {}
      const streamUrlsParsed = reduceUrlByType(streamUrls) as StreamUrlParsed
      const DrmConfigsParsed: DRMConfig = reduceUrlByType(drmConfigs, true)

      const videoUrl = streamUrls?.[0]

      const playerSource = {
        dash: videoUrl ? (videoUrl.type === StreamUrlType.DASH ? videoUrl.url : null) : defaultSourceConfig.dash,
        hls: videoUrl && videoUrl.type === StreamUrlType.HLS && videoUrl.url,
        progressive: streamUrlsParsed?.progressive || defaultSourceConfig.progressive,
        poster: videoUrl ? posterImage : defaultSourceConfig.poster,
        drm: DrmConfigsParsed,
      }

      const adBreaks = reduceAdsConfig(adsConfig)
      const advertising: AdvertisingConfig = { adBreaks }
      const playerConfig: PlayerConfig = {
        ...defaultPlayerConfig,
        advertising,
        analytics: {
          ...defaultPlayerConfig.analytics,
          videoId: slug,
          title: title ?? slug,
        },
      }

      const playerInstance = new Player(playerDiv.current, playerConfig)

      playerInstance.load(playerSource).then(
        () => {
          setPlayer(playerInstance)
          console.log('Successfully loaded source')
        },
        (err) => {
          console.error(err, 'Error while loading source')
        },
      )
    }

    if ((!player && videoConfig) || loadDefaultVideo) {
      setupPlayer()
      setLoadDefaultVideo(false)
    }

    return () => {
      dispatch(setIsPlaying(true))
    }
  }, [videoConfig, player, showMenu, slug, dispatch, loadDefaultVideo, posterImage])

  useEffect(() => {
    if (player) {
      dispatch(setSubtitleList(player.subtitles?.list()))
      dispatch(setAudioList(player.getAvailableAudio()))
    }
  }, [dispatch, player])

  useEffect(() => {
    if (player) {
      const bitmovinVideo = playerDiv.current.children[0]
      bitmovinVideo?.setAttribute('crossorigin', '')

      const currentSubtitle: Subtitle = subtitlesList.find((subtitle) => subtitle.id === savedSubtitle)

      const trackElement = document.querySelector('track')
      trackElement && trackElement.remove()

      if (currentSubtitle) {
        const trackElement = document.createElement('track')
        trackElement.default = 'default'
        trackElement.kind = 'captions'
        trackElement.srclang = currentSubtitle.lang
        trackElement.src = currentSubtitle.url

        bitmovinVideo?.appendChild(trackElement)
      }
    }
  }, [player, savedSubtitle, subtitlesList])

  useEffect(() => {
    if (!savedAudio) {
      const defaultAudioFromVideo = player && player.getAudio()?.id
      dispatch(setAudio(defaultAudioFromVideo))
    } else {
      if (findAudioFromStore) {
        player && player.setAudio(findAudioFromStore.id)
      } else {
        dispatch(setAudio(player && player.getAudio().id))
      }
    }

    if (!savedSubtitle) {
      dispatch(setSubtitle('subtitle-off'))
    }
  }, [audioList, dispatch, findAudioFromStore, player, savedAudio, savedSubtitle, subtitlesList])

  return (
    <div className={classNames('player-wrapper', adIsPlaying && 'disabled-pointer-events')}>
      {isLoading ? (
        <DotSpinner />
      ) : (
        <>
          <div id="player" ref={playerDiv} />
          {!adIsPlaying && currentTime > 0 && (
            <PlayerControls
              play={play}
              pause={pause}
              hasPlaybackEnded={hasPlaybackEnded}
              videoConfig={videoConfig}
              playFromBeginning={playFromBeginning}
              showModal={showModal}
              setShowModal={setShowModal}
              adIsPlaying={adIsPlaying}
              currentTime={currentTime}
              duration={duration}
              playFromPosition={playFromPosition}
              progressRef={progressRef}
              player={player}
              setPlayer={setPlayer}
            />
          )}
        </>
      )}
    </div>
  )
}
