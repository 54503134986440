import { QueryParams } from '@services/dynamic-page'
import { platformType, subscriptionPlanType } from './subscription.const'

export const KeyName = {
  ArrowUp: 'ArrowUp',
  ArrowDown: 'ArrowDown',
  ArrowLeft: 'ArrowLeft',
  ArrowRight: 'ArrowRight',
  Enter: 'Enter',
  Escape: 'Escape',
  MediaPlay: 'MediaPlay',
  MediaPause: 'MediaPause',
  MediaPlayPause: 'MediaPlayPause',
}

export const KeyCode = {
  TizenBackKey: 10009,
  WebosBackKey: 461,
  Enter: 13,
  MediaPlayKey: 415,
  MediaPauseKey: 19,
  MediaPlayPauseKey: 10252,
}

export const CardTypes = {
  HORIZONTAL_CATEGORY: 'vel:cardtype:hcat',
  HORIZONTAL_SIMPLE: 'vel:cardtype:hs',
  HORIZONTAL_CONTINUE_WATCHING: 'vel:cardtype:hcw',
  HORIZONTAL_COMPLICATED: 'vel:cardtype:hc',
  HORIZONTAL_COMPLICATED_DATE: 'vel:cardtype:hcd',
  VERTICAL_SIMPLE: 'vel:cardtype:vs',
  HORIZONTAL_SIMPLE_POSTER_CAROUSEL: 'vel:displaytype:simplemoviecard', //:hspc
  SEARCH: 'searchRow', // :ht (horizontal title card)
  MY_LIST: 'myList', // :ht (horizontal title card)
  EPISODES: 'episode', // :ec (episode card)
}

export const PageTemplates = {
  FEED: 'vel:pagetype:feed',
  LOGIN: 'vel:pagetype:login',
  DETAILS: 'vel:pagetype:moviedetail',
}

export const Language = {
  EN: 'en',
  ES: 'es',
}

export const Months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const ButtonIcon = {
  TICK: 'TICK',
  PLAY: 'PLAY',
  PLUS: 'PLUS',
  INFO: 'INFO',
}

export type ScrollBehavior = 'auto' | 'smooth'

export const splidesPerMove = 1
export const carouselHeroRailInterval = 5000

export type Direction = 'left' | 'right'

export const noResultsQueryParams: QueryParams = {
  platform: platformType.CTV,
  product: subscriptionPlanType.FREE,
  q: '',
  page: '0',
  perpage: '9',
}

export const ImageOverrides = {
  format: 't_IMG_FORMAT',
  extension: 'IMG_EXT',
}
