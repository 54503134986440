import { SKELETON_IMAGE_TYPES } from '@constants/skeleton.const'
import './Image.scss'
import { useState } from 'react'
import classNames from 'classnames'

interface ImageProps {
  type?: (typeof SKELETON_IMAGE_TYPES)[number]
  className?: string
  image: string | undefined
  alt?: string
  width?: string
  height?: string
}

export const Image = ({ type, className, image, alt, width, height }: ImageProps) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false)

  const handleIsImageLoaded = () => {
    setIsImageLoaded(true)
  }

  return (
    <div className={`image-wrapper ${type || SKELETON_IMAGE_TYPES[2]}`}>
      <img
        className={classNames(isImageLoaded ? 'visible' : 'hidden', className)}
        src={image}
        alt={alt || 'image'}
        onLoad={handleIsImageLoaded}
        width={width}
        height={height}
      />
    </div>
  )
}

export default Image
