import React from 'react'
import './HorizontalComplicatedCard.scss'
import classNames from 'classnames'
import { CardProps, Genre, RailDetailsItem } from '@interfaces/index'
import { formatSecondsToHoursAndMinutes } from '@utils/helpers'
import { DocumentData } from 'firebase/firestore'
import { useTypedSelector } from 'store'
import { ImageSize } from '@enums/images.enum'
import { TextTag } from '@enums/textTag'
import Image from '@components/Image/Image'
import Text from '@components/Text/Text'
import { useImgUrlTransform } from '@hooks/useImgUrlTransform'

interface HorizontalComplicatedCardProps {
  item: CardProps | RailDetailsItem | DocumentData
  isFocusable?: boolean
  lastFocusedCard?: () => void
  isActive?: boolean
}

const HorizontalComplicatedCard = ({ item, isActive }: HorizontalComplicatedCardProps) => {
  const config = useTypedSelector((state) => state.config.data)
  const fallbackImage = config?.features.fallbackImages.horizontalCard ?? ''
  const {
    title,
    contextData: { imageBackdrop, genres, duration, imageCard },
  } = item
  const transformedImage = useImgUrlTransform(fallbackImage, imageCard ?? imageBackdrop, ImageSize.HORIZONTAL_CARD)

  return (
    <div
      className={classNames('hc-type', {
        'hc-type__is_active': isActive,
        'hc-type__full_opacity': isActive,
      })}
    >
      <div className="img-placeholder"></div>
      <div className="hc-type__top-container">
        <div
          className={classNames('hc-type__focus', {
            'hc-type__focused': isActive,
          })}
        ></div>
        <div className={classNames('card-image-wrapper', { 'row-card-image-focused': isActive })}>
          <Image image={transformedImage} alt={title} />
        </div>
        <div className={classNames(`tags-container`, { 'tags-container__is_active': isActive })}>
          {genres?.map((genre: Genre, index: number) =>
            index <= 1 ? (
              <Text tag={TextTag.P} key={genre.name} className="single-tag">
                {genre.name}
              </Text>
            ) : null,
          )}
        </div>
        {duration && (
          <div className={classNames('duration-container', { 'duration-container__is_active': isActive })}>
            {formatSecondsToHoursAndMinutes(duration)}
          </div>
        )}
      </div>
      <div className={'hc-type__bottom-container hc-type__bottom-container-two-lines'}>
        <Text tag={TextTag.P}>{title}</Text>
      </div>
    </div>
  )
}
export default HorizontalComplicatedCard
