import React from 'react'
import './HorizontalContinueWatchingCard.scss'
import classNames from 'classnames'
import { CardProps, RailDetailsItem } from '@interfaces/index'
import { ImageSize } from '@enums/images.enum'
import { useImgUrlTransform } from '@hooks/useImgUrlTransform'
import { DocumentData } from 'firebase/firestore'
import { useTypedSelector } from 'store'
import Image from '@components/Image/Image'
import Text from '@components/Text/Text'
import { categoryType } from '@constants/category.const'
import { TextTag } from '@enums/textTag'

interface HorizontalContinueWatchingCardProps {
  item: CardProps | RailDetailsItem | DocumentData
  isActive?: boolean
}

const HorizontalContinueWatchingCard = ({ item, isActive }: HorizontalContinueWatchingCardProps) => {
  const config = useTypedSelector((state) => state.config.data)
  const fallbackImage = config?.features.fallbackImages.horizontalCard ?? ''
  const {
    title,
    subtitle,
    contextData: { imageBackdrop, imageCard },
    callToActions,
  } = item
  const transformedImage = useImgUrlTransform(fallbackImage, imageCard ?? imageBackdrop, ImageSize.HORIZONTAL_CARD)
  const category = callToActions?.webUrl?.split('/')[1]

  const renderProgress = (isActive?: boolean, duration?: string) => {
    const total = duration ? parseInt(duration.split(':')[0]) : 0
    const watched = total * 0.6
    const width = total > 0 ? ((total - watched) / total) * 100 : 0
    return (
      <div className={`hcw-type__progress ${isActive && `hcw-type__progress--focus`}`}>
        <div className={`hcw-type__progress--active`} style={{ width: `${width}%` }}></div>
      </div>
    )
  }
  const progressTimeTrack = '12:25'

  return (
    <div className="hcw-type">
      <div
        className={classNames('hcw-type__focus', {
          'hcw-type__focused': isActive,
        })}
      >
        <div className={`hcw-type__focus--wrapper`}>
          <Text tag={TextTag.P} className={`hcw-type__focus--wrapper--title`}>
            {title}
          </Text>
          <Text tag={TextTag.P} className={`hcw-type__focus--wrapper--subtitle`}>
            {category === categoryType.SERIE && subtitle}
          </Text>
        </div>
        {renderProgress(isActive, progressTimeTrack)}
      </div>
      <div
        className={classNames(`hcw-type__container`, {
          'hcw-type__focused': isActive,
        })}
      >
        <div className={classNames('card-image-wrapper', { 'row-card-image-focused': isActive })}>
          <Image image={transformedImage} alt={title} />
        </div>
        {!isActive && renderProgress(isActive, progressTimeTrack)}
      </div>
    </div>
  )
}
export default HorizontalContinueWatchingCard
