import React from 'react'
import './HorizontalCategoryCard.scss'
import classNames from 'classnames'
import { CardProps, RailDetailsItem } from '@interfaces/index'
import { TextTag } from '@enums/textTag'
import Text from '@components/Text/Text'
import { DocumentData } from 'firebase/firestore'

interface HorizontalCategoryCardProps {
  item: CardProps | RailDetailsItem | DocumentData
  isActive?: boolean
}

const HorizontalCategoryCard = ({ item, isActive }: HorizontalCategoryCardProps) => {
  const { title } = item

  return (
    <div
      className={classNames('hcat-type', {
        'hcat-type__is_active': isActive,
      })}
    >
      <Text tag={TextTag.P}>{title}</Text>
    </div>
  )
}
export default HorizontalCategoryCard
