export enum TabsDetails {
  ACTORS = 'actors',
  SOUNDTRACKS = 'soundtracks',
  EXTRAS = 'extras',
  EPISODES = 'episodes',
}

export enum ActorsViewType {
  SQUARED = 'squared',
  RECTANGULAR = 'rectangular',
  ROUNDED = 'rounded',
  OVERLAY = 'overlayed',
}

export enum SoundtracksViewType {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}
