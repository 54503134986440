import React from 'react'
import { Direction, KeyCode, KeyName } from '../constants'
import classNames from 'classnames'
import { ImageSize } from '@enums/images.enum'
import { ActorsViewType } from '@enums/details.enum'

export const changeRouteAccordingToCategory = (category: string) => {
  if (category === 'movie') {
    return 'movies'
  } else if (category === 'serie') {
    return 'series'
  } else return ''
}

export const checkTimeLocalStorage = (
  id: string | undefined,
  category: string | undefined,
  setIsStarted: (value: boolean) => void,
) => {
  if (localStorage.getItem('timePaused') === null) {
    localStorage.setItem('timePaused', JSON.stringify([]))
  } else {
    const timePaused = JSON.parse(localStorage.getItem('timePaused') || '{}')
    timePaused.forEach((movie: { id: string; contentCategory: string; time: number }) => {
      if (movie.id === id && movie.contentCategory === category && movie.time > 0) {
        setIsStarted(true)
      }
    })
  }
}

export const onEnterKeyPress = (e: React.KeyboardEvent, onClick: () => void) => {
  if (e.key === KeyName.Enter || e.keyCode === KeyCode.Enter) {
    onClick()
  }
}

export const onBackKeyPress = (e: KeyboardEvent, onClick: () => void) => {
  if (e.key === KeyName.Escape || e.keyCode === KeyCode.TizenBackKey || e.keyCode === KeyCode.WebosBackKey) {
    onClick()
  }
}

export const onBackKeyPressAndClick = (e: React.KeyboardEvent | React.MouseEvent, onClick: () => void) => {
  const keyboardEvent = e as React.KeyboardEvent
  if (
    e.type === 'click' ||
    (e.type === 'keydown' &&
      (keyboardEvent.key === KeyName.Escape ||
        keyboardEvent.keyCode === KeyCode.TizenBackKey ||
        keyboardEvent.keyCode === KeyCode.WebosBackKey))
  ) {
    onClick()
  }
}

export const onEnterKeyPressAndClick = (e: React.KeyboardEvent | React.MouseEvent, onClick: () => void) => {
  if (
    e.type === 'click' ||
    (e.type === 'keydown' &&
      (e as React.KeyboardEvent).key === KeyName.Enter &&
      (e as React.KeyboardEvent).keyCode === 13)
  ) {
    onClick()
  }
}

export const createAlt = (imageUrl: string): string => imageUrl?.split('/')?.pop()?.replace('.', '') || ''

export function flatten<T>(arr: T[][]): T[] {
  return ([] as T[]).concat(...arr)
}

export const openMenuOnBackKey = (e: KeyboardEvent) => {
  onBackKeyPress(e, () => {
    const menu = document.getElementById('menu')
    const activeMenuElement = menu?.querySelector('.active') as HTMLElement
    activeMenuElement?.focus()
  })
}

export const formatSecondsToHoursAndMinutes = (seconds: number) => {
  const totalSeconds = Math.floor(seconds)
  const hours = Math.floor(totalSeconds / 3600)
  const minutes = Math.floor((totalSeconds % 3600) / 60)

  const formattedHours = hours < 10 ? `${hours}` : hours.toString()
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes.toString()

  return `${formattedHours}h ${formattedMinutes}m`
}

export const convertDate = (dateProp: string) => {
  const splitedDate = dateProp.split('-')
  const month = splitedDate[1]
  const day = splitedDate[2]
  const dayLastCharacter = day.slice(-1)

  let daySuffix
  let monthName

  switch (dayLastCharacter) {
    case '1':
      daySuffix = 'st'
      break

    case '2':
      daySuffix = 'nd'
      break

    case '3':
      daySuffix = 'rd'
      break

    default:
      daySuffix = 'th'
      break
  }

  switch (month) {
    case '01':
      monthName = 'January'
      break
    case '02':
      monthName = 'February'
      break
    case '03':
      monthName = 'March'
      break
    case '04':
      monthName = 'April'
      break
    case '05':
      monthName = 'May'
      break
    case '06':
      monthName = 'June'
      break
    case '07':
      monthName = 'July'
      break
    case '08':
      monthName = 'August'
      break
    case '09':
      monthName = 'September'
      break
    case '10':
      monthName = 'October'
      break
    case '11':
      monthName = 'November'
      break
    case '12':
      monthName = 'December'
      break
  }

  let formattedDate = `${day}${daySuffix} ${monthName}`
  return formattedDate
}

export const ageRateColorClass = (age: string | undefined) => {
  if (age === '+16') {
    return 'ageRating16'
  } else if (age === '+12') {
    return 'ageRating12'
  } else if (age === '+7') {
    return 'ageRating7'
  } else if (age === '+0') {
    return 'ageRating0'
  }
}

export const convertHexToRgb = (color: string) => {
  const hexValue = color.replace('#', '')
  const r = parseInt(hexValue.substring(0, 2), 16)
  const g = parseInt(hexValue.substring(2, 4), 16)
  const b = parseInt(hexValue.substring(4, 6), 16)
  return `${r}, ${g}, ${b}`
}

export const emailLoginPattern =
  /* eslint-disable-next-line */
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i

export const formatTime = (time: number) => {
  const hours = Math.floor(time / 3600)
  const minutes = Math.floor((time % 3600) / 60)
  const seconds = Math.floor(time % 60)

  return `${hours}:${formatDigit(minutes)}:${formatDigit(seconds)}`
}

const formatDigit = (num: number) => {
  return (num < 10 ? '0' : '') + num
}

export const addLastFocusedItemToSS = (id: string, page: string) => {
  sessionStorage.setItem(`lastFocusedCard-${page}`, JSON.stringify(id))
}

export const addRailItemToSS = (
  railId: string,
  cardId: string,
  cardIndex: number,
  homeSlug: string,
  heroFocusOn?: string,
) => {
  sessionStorage.setItem(
    'lastFocusedCard-home',
    JSON.stringify({
      railId: railId,
      cardId: cardId,
      cardIndex: cardIndex,
      homeSlug: homeSlug,
      heroFocusOn: heroFocusOn,
    }),
  )
}

export const moveInputCursor = (direction: Direction, element: HTMLInputElement | null) => {
  let cursorPoint = element?.value.length
  switch (direction) {
    case 'left':
      if (element?.selectionEnd) {
        cursorPoint = element?.selectionEnd - 1
        element?.setSelectionRange(element.value.length, cursorPoint, 'backward')
      }
      break
    case 'right':
      if (element?.selectionEnd || element?.selectionEnd === 0) {
        cursorPoint = element?.selectionEnd + 1
        element?.setSelectionRange(element.value.length, cursorPoint, 'forward')
      }
      break
    default:
      element?.setSelectionRange(element?.selectionStart, element?.selectionEnd)
      break
  }
}

export const addSynopsisClass = (value: string, conditions: boolean[]) => {
  const fullWidth = conditions.some((v) => v)
  const addMargin = conditions.filter((v) => v).length === 1
  return classNames(`synopsis-${value}`, { 'synopsis-full-width': fullWidth, 'synopsis-gap': addMargin })
}

export const actorImageSize = (actorViewTypeClass: ActorsViewType | string) => {
  switch (actorViewTypeClass) {
    case ActorsViewType.RECTANGULAR:
      return ImageSize.ACTOR_RECTANGULAR
    case ActorsViewType.ROUNDED:
      return ImageSize.ACTOR_ROUNDED
    case ActorsViewType.SQUARED:
      return ImageSize.ACTOR_SQUARED
    default:
      return ActorsViewType.SQUARED
  }
}
