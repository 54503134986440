import React from 'react'
import './HorizontalCardWithTitle.scss'
import { CardProps, RailDetailsItem } from '@interfaces/index'
import { ImageSize } from '@enums/images.enum'
import { useImgUrlTransform } from '@hooks/useImgUrlTransform'
import { useTypedSelector } from 'store'
import Image from '@components/Image/Image'
import Text from '@components/Text/Text'
import { TextTag } from '@enums/textTag'
import { DocumentData } from 'firebase/firestore'
import { useLocation } from 'react-router-dom'
import { routes } from '@constants/routes.const'

interface HorizontalCardWithTitleProps {
  item: CardProps | RailDetailsItem | DocumentData
}

const HorizontalCardWithTitle = ({ item }: HorizontalCardWithTitleProps) => {
  const location = useLocation()
  const config = useTypedSelector((state) => state.config.data)
  const fallbackImage = config?.features.fallbackImages.horizontalCard ?? ''
  const { title, contextData } = item
  const image = location.pathname.includes(routes.MY_LIST)
    ? item.image
    : contextData.imageCard ?? contextData.imageBackdrop
  const transformedImage = useImgUrlTransform(fallbackImage, image, ImageSize.HORIZONTAL_CARD)

  return (
    <div className="ht-type" id={item.id}>
      <div className="card-top">
        <div className="card-image-wrapper">
          <Image image={transformedImage} alt={title} />
        </div>
      </div>
      <div className="card-bottom">
        <Text tag={TextTag.P}>{title}</Text>
      </div>
    </div>
  )
}
export default HorizontalCardWithTitle
