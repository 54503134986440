export enum ImageExtension {
  JPG = 'jpg',
  PNG = 'png',
  WEBP = 'webp',
}

export enum ImageSize {
  VERTICAL_CARD = 'ar_5:4,w_320',
  HORIZONTAL_CARD = 'ar_16:9,w_320',
  CAROUSEL = 'ar_7:2,w_1684',
  HERO = 'ar_16:9,w_1344',
  TITLE = 'ar_16:9,w_1000',
  SOUNDTRACK = 'ar_1:1,w_240',
  ACTOR_SQUARED = 'ar_1:1,w_230',
  ACTOR_ROUNDED = 'ar_1:1,w_240',
  ACTOR_RECTANGULAR = 'ar_1:1,w_240',
  VIDEO_THUMBNAIL = 'ar_1:1,w_1000',
}
